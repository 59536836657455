export default {
    buyPlan: {
        title: 'Buy Plan',
        heading: {
            subscribeNow:
                'Subscribe now to Cybooks and digitalize your Accounting in Cyprus',
            goBack: 'Go back to Cybooks',
            freeTrial: 'Free trial - 1 days left',

            yourTrialExpired: (item) => {
                const d = item.named('d')
                const h = item.named('h')
                const m = item.named('m')
                const s = item.named('s')

                if (d > 0) {
                    return `Free trial - ${d} days left`
                }

                if (h > 0) {
                    return `Free trial - ${d} hours left`
                }

                if (m > 0) {
                    return `Free trial - ${m} minutes left`
                }

                if (s > 0) {
                    return `Free trial - ${s} seconds left`
                }

                return 'Your free trial expired'
            },
        },

        index: {
            title: 'Buy a plan',
            description: 'Review your purchase',
            planDescription: `Your plan will start today and automatically renew every month. We'll
                invoice you today.<br /><br />
                If, at any time, you decide to cancel your subscription you must do this
                at least 30 days before your plan renews on 08 Oct 2024.<br /><br />
                By clicking buy now, you accept our
                <a href="" class="text-primary-600">terms and conditions</a> and
                <a href="#" class="text-primary-600">privacy policy.</a>`,
        },

        address: {
            title: 'Business address',
            description:
                'Please provide the address where your business is located, and where we should send your Cybooks invoices',
            companyName: 'Company name',
            address1: 'Address line 1',
            address2: 'Address line 2',
            postcode: 'Postcode',
            city: 'City',
            vatID: 'VAT ID',
        },

        buyNow: 'Buy now',
        billed: ({ named }) => {
            const oneTime = named('oneTime')
            const price = named('price')
            if (oneTime) {
                return `${price} (excluding taxes) billed one time`
            }

            return `${price} (excluding taxes) billed monthly`
        },
        enterDiscountCode: 'Enter Discount code',
        totalExclTaxes: 'Total (excl. taxes)',
        canNotApplyThisCodeToThisPlan: "Can't apply this code to this plan",
        pricePerMonth: ({ named }) => {
            const oneTime = named('oneTime')
            const price = named('price')
            if (oneTime) {
                return `${price} one time`
            }

            return `${price} per month`
        },
    },
}
