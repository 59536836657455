<template>
    <div class="bg-white py-5">
        <div class="container mx-auto flex items-center justify-between px-4">
            <div class="flex items-center">
                <base-logo />
                <span class="mr-2.5 text-sm font-normal text-gray-600">
                    {{ $t('buyPlan.heading.subscribeNow') }}
                </span>
                <base-badge
                    v-if="trialEndDiff"
                    :label="
                        $t('buyPlan.heading.yourTrialExpired', {
                            d: trialEndDiff.day,
                            h: trialEndDiff.hour,
                            m: trialEndDiff.minute,
                            s: trialEndDiff.second,
                        })
                    "
                />
            </div>
            <base-button variant="default" @click="goBack">
                {{ $t('buyPlan.heading.goBack') }}
            </base-button>
        </div>
    </div>
    <div class="mx-auto w-[495px] pb-10 pt-16">
        <div class="rounded-lg border border-gray-200 bg-white px-10 py-5">
            <router-view v-if="plan" :plan="plan" @on-submit="onSubmit" />
        </div>
    </div>
</template>

<script setup>
const { t } = useI18n()
const emitter = useEmitter()
const router = useRouter()
const route = useRoute()
const { company } = useAuth()

useHead({
    title: t('buyPlan.title'),
})

let trialCountdown = null

const { handleSubmit, setErrors } = useForm()
const { execute: executeChangePlan } = useApi(
    '/api/subscription/change-plan',
    'POST'
)

const { execute: getPlan, result: plan } = useApi(
    `/api/subscription/plans/{id}`,
    'GET'
)

onMounted(() => {
    emitter.emit('set-loading', true)
    getPlan({
        params: { id: route.params.id },
    }).finally(() => {
        emitter.emit('set-loading', false)
    })
})

onMounted(() => {
    router.push({
        name: 'buy-plan.index',
        params: {
            id: route.params.id,
        },
    })

    trialCountdown = setInterval(() => {
        if (!trialEndDiff.value) {
            clearTrialCountdown()
            return
        }

        trialEndDiff.value.second--
    }, 1000)
})

const trialEndDiff = computed(() => company.value?.trial_end_diff)

onBeforeUnmount(() => {
    clearTrialCountdown()
})

const clearTrialCountdown = () => {
    if (trialCountdown) {
        clearInterval(trialCountdown)
    }
}

watch(
    () => trialEndDiff.value,
    () => {
        if (!trialEndDiff.value) {
            return
        }

        const { second, minute, hour, day } = trialEndDiff.value
        if (second <= 0 && minute <= 0 && hour <= 0 && day <= 0) {
            clearTrialCountdown()
            return
        }

        if (second >= 0) {
            return
        }

        trialEndDiff.value.second = 59
        if (minute > 0) {
            trialEndDiff.value.minute--
            return
        }

        trialEndDiff.value.minute = 59
        if (hour > 0) {
            trialEndDiff.value.hour--
            return
        }

        trialEndDiff.value.hour = 23
        if (day > 0) {
            trialEndDiff.value.day--
        }
    },
    { deep: true }
)

const goBack = () => {
    router.push({ name: 'subscriptions.change' })
}

const onSubmit = handleSubmit((values) => {
    emitter.emit('set-loading', true)
    executeChangePlan({
        data: {
            ...values,
            price_id: plan.value.price_id,
        },
    })
        .then((redirectUrl) => {
            if (redirectUrl) {
                window.open(redirectUrl, '_self')
                return
            }

            router.push({ name: 'subscriptions.index' })
        })
        .catch(({ errors }) => setErrors(errors))
        .finally(() => {
            emitter.emit('set-loading', false)
        })
})
</script>
