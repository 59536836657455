<template>
    <base-heading
        :title="$t('buyPlan.index.title')"
        :description="$t('buyPlan.index.description')"
        class="mb-6"
    />
    <div class="mb-6 rounded bg-gray-100 p-3.5">
        <div class="flex flex-col gap-y-3.5 p-px">
            <div class="flex gap-1 text-black">
                <span class="block min-w-24 text-sm font-bold">
                    {{ $t('general.plan') }}
                </span>
                <div class="flex flex-col gap-2.5 text-sm font-normal">
                    <span>{{ plan.name }}</span>
                    <span>
                        {{
                            $t('subscriptions.interval', {
                                oneTime: plan.is_pay_once,
                            })
                        }}
                    </span>
                </div>
            </div>
            <div class="flex gap-1 text-black">
                <span class="block min-w-24 text-sm font-bold">
                    {{ $t('general.price') }}
                </span>
                <div class="flex flex-col gap-2.5 text-sm font-normal">
                    <span>
                        {{
                            $t('buyPlan.billed', {
                                oneTime: plan.is_pay_once,
                                price: $filters.currency(
                                    plan.price,
                                    plan.currency.iso
                                ),
                            })
                        }}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="mb-2.5 flex flex-col gap-2.5 text-sm font-normal text-black">
        <div class="flex justify-between gap-2.5">
            <span>
                {{ plan.name }}
            </span>
            <span>
                {{ $filters.currency(plan.price, plan.currency.iso) }}
            </span>
        </div>
        <hr />
        <template v-if="promotion">
            <div class="flex justify-between gap-2.5">
                <base-badge variant="gray" :label="promotion.code">
                    <template #right>
                        <base-icon
                            variant="gray"
                            name="line-icons:general:x"
                            class="cursor-pointer"
                            size="sm"
                            @click="promotion = null"
                        />
                    </template>
                </base-badge>
                <span>
                    {{ $filters.symbolCurrency(promotionPrice, plan.currency) }}
                    ({{ $filters.numberFormat(promotion.percent) }}%)
                </span>
            </div>
            <hr />
        </template>
        <template v-else>
            <div class="flex justify-between gap-2.5">
                <form-text-input
                    v-model="promotionCode"
                    size="sm"
                    :placeholder="$t('buyPlan.enterDiscountCode')"
                />
                <base-button
                    :disabled="!promotionCode"
                    :loading="loadingApplyPromotion"
                    variant="default"
                    size="sm"
                    @click="onApplyPromotion"
                >
                    {{ $t('general.apply') }}
                </base-button>
            </div>
            <hr />
        </template>
        <div class="flex justify-between gap-2.5 text-md font-semibold">
            <span>{{ $t('buyPlan.totalExclTaxes') }}</span>
            <span>
                {{
                    $t('buyPlan.pricePerMonth', {
                        oneTime: plan.is_pay_once,
                        price: $filters.symbolCurrency(
                            totalPrice,
                            plan.currency
                        ),
                    })
                }}
            </span>
        </div>
        <hr />
        <span
            class="block text-xs font-normal text-gray-900"
            v-html="$t('buyPlan.index.planDescription')"
        />
        <hr />
    </div>

    <div class="mb-2.5 flex justify-end gap-2.5">
        <base-button variant="default" size="sm" @click="onBack">
            {{ $t('general.back') }}
        </base-button>
        <base-button size="sm" @click="onBuy">
            {{ $t('buyPlan.buyNow') }}
        </base-button>
    </div>
</template>
<script setup>
import BigNumber from 'bignumber.js'

const { company } = useAuth()
const discount = computed(() => company.value?.subscription_discount)

const props = defineProps({
    plan: {
        type: Object,
        required: true,
    },
})

const route = useRoute()
const router = useRouter()
const { t } = useI18n()

onMounted(() => {
    if (!props.plan.is_pay_once && discount.value) {
        applyPromotion(discount.value.code)
    }
})

const promotionCode = ref()
const { errorNotify } = useNotification()

const promotionPrice = computed(() => {
    if (!promotion.value) {
        return 0
    }

    return (props.plan.price * promotion.value.percent) / 100
})

const totalPrice = computed(() => {
    return new BigNumber(props.plan.price)
        .minus(promotionPrice.value)
        .toNumber()
})

const { value: promotion, setValue: setPromotion } = useField(
    'promotion',
    null,
    {
        keepValueOnUnmount: true,
    }
)
const { execute: executeApplyPromotion, loading: loadingApplyPromotion } =
    useApi(`/api/subscription/apply-promotion`, 'POST')

const onBack = () => {
    router.push({
        name: 'subscriptions.change',
    })
}

const onBuy = () => {
    router.push({
        name: 'buy-plan.address',
        params: {
            id: route.params.id,
        },
    })
}

const onApplyPromotion = () => {
    if (
        props.plan.is_pay_once &&
        promotionCode.value === discount.value?.code
    ) {
        errorNotify({
            title: t('general.error'),
            text: t('buyPlan.canNotApplyThisCodeToThisPlan'),
        })
        return
    }

    return applyPromotion(promotionCode.value)
}

const applyPromotion = (promotion) => {
    return executeApplyPromotion({
        data: {
            promotion,
        },
    })
        .then((data) => {
            setPromotion(data)
        })
        .catch(({ errorMessage }) => {
            errorNotify({
                title: t('general.error'),
                text: errorMessage,
            })
        })
}
</script>
