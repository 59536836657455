import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'buy-plan',
        path: '/buy-plan',
        component: () => import('./pages/buy-plan.vue'),
        children: [
            {
                name: 'buy-plan.index',
                path: ':id',
                component: () => import('./views/buy-plan-index.vue'),
            },
            {
                name: 'buy-plan.address',
                path: ':id/address',
                component: () => import('./views/buy-plan-address.vue'),
            },
        ],
    },
]

export default {
    routes,
    meta: {
        layout: 'blank-layout',
        guard: [GUARD.TENANT, GUARD.SUBSCRIPTION],
        permission: 'access',
    },
}
