<template>
    <base-heading
        :title="$t('buyPlan.address.title')"
        :description="$t('buyPlan.address.description')"
        class="mb-6"
    />
    <div class="flex flex-col gap-2.5 pb-2.5">
        <form-text-input
            :label="$t('buyPlan.address.companyName')"
            :placeholder="$t('buyPlan.address.companyName')"
            v-model="companyName"
            :error-message="errorCompanyName"
        />
        <form-text-input
            :label="$t('buyPlan.address.address1')"
            :placeholder="$t('buyPlan.address.address1')"
            v-model="address1"
            :error-message="errorAddress1"
        />
        <form-text-input
            :label="$t('buyPlan.address.address2')"
            :placeholder="$t('buyPlan.address.address2')"
            v-model="address2"
            :error-message="errorAddress2"
        />
        <div class="flex gap-2.5">
            <form-text-input
                :label="$t('buyPlan.address.postcode')"
                :placeholder="$t('buyPlan.address.postcode')"
                v-model="postcode"
                :error-message="errorPostcode"
            />
            <form-text-input
                :label="$t('buyPlan.address.city')"
                :placeholder="$t('buyPlan.address.city')"
                v-model="city"
                :error-message="errorCity"
            />
        </div>
        <form-text-input
            :label="$t('buyPlan.address.vatID')"
            :placeholder="$t('buyPlan.address.vatID')"
            v-model="vatID"
            :error-message="errorVatID"
        />
        <hr class="my-2.5" />
    </div>

    <div class="mb-2.5 flex justify-end gap-2.5">
        <base-button variant="default" size="sm" @click="router.back">
            {{ $t('general.back') }}
        </base-button>
        <base-button size="sm" @click="emit('on-submit')">
            {{ $t('general.next') }}
        </base-button>
    </div>
</template>
<script setup>
import { useSetFormValues } from 'vee-validate'

const emit = defineEmits(['on-submit'])
const emitter = useEmitter()
const router = useRouter()
const setValues = useSetFormValues()
const { useApiGet } = useApiFactory('companies')
const { execute: executeGet } = useApiGet()

const { value: companyName, errorMessage: errorCompanyName } =
    useField('company_name')
const { value: address1, errorMessage: errorAddress1 } = useField('address1')
const { value: address2, errorMessage: errorAddress2 } = useField('address2')
const { value: postcode, errorMessage: errorPostcode } = useField('postcode')
const { value: city, errorMessage: errorCity } = useField('city')
const { value: vatID, errorMessage: errorVatID } = useField('vat_id')

onMounted(async () => {
    emitter.emit('set-loading', true)

    const { company } = await executeGet()
    const { company_name, address1, address2, postcode, city, vat_id } =
        company || {}

    setValues({
        company_name,
        address1,
        address2,
        postcode,
        city,
        vat_id,
    })

    emitter.emit('set-loading', false)
})
</script>
